import { gql } from '@apollo/client';
import genericFields from './genericFields';

const bookmarksFragmentQuery = gql`
  fragment bookmarksItemFields on Item {
    ${genericFields}
    photos(limit: 4) {
      thumbnail
      f460x345
      large
    }
  }
`;

export const bookmarkIdsQuery = gql`
  query BookmarkIds {
    bookmarks {
      __typename
      item {
        __typename
        id
      }
    }
  }
`;

export const bookmarksAllQuery = gql`
  query BookmarksAll {
    bookmarks {
      item {
        ...bookmarksItemFields
      }
    }
  }
  ${bookmarksFragmentQuery}
`;

const temp = 'just for eslint';

export default temp;
