import { gql } from '@apollo/client';
import genericFields from './genericFields';

const agencyItemsFragmentQuery = gql`
  fragment agencyItemFields on Item {
    ${genericFields}
    photos(limit: 1) {
      thumbnail
    }
  }
`;

export const getInitialItems = gql`
  query InitialItems {
    residences(limit: 4, sort: RANDOM) {
      id
      name
      price {
        perUnit
        total {
          minimum
          maximum
        }
        currency
      }
      address
      shortAddress
      deadline
      coverUrl
      path
      metroName
      companyName
    }
    agencies(limit: 16, sort: RANDOM) {
      items(limit: 1, sort: RANDOM) {
        ...agencyItemFields
      }
    }
  }
  ${agencyItemsFragmentQuery}
`;
