import { gql } from '@apollo/client';

export function locationsQuery({ cityId, scope, limit }) {
  const name = `LocationsQuery_${cityId}_${scope}_${limit}`;
  return gql`
    query ${name}($cityId: ID, $scope: LocationScopeEnum!, $limit: Int!) {
      locations(filter: { scope: $scope, cityId: $cityId }, limit: $limit) {
        id
        name
        fullName
        locationGroupId
        slug
      }
    }
  `;
}

export function cityAndLocationsQuery({ cityId, scope, limit }) {
  const name = `CityAndLocationsQuery_${cityId}_${scope}_${limit}`;
  return gql`
    query ${name}($cityId: ID!, $scope: LocationScopeEnum!, $limit: Int!) {
      city(id: $cityId) {
        id
        name
        slug
        latitude
        longitude
      }
      locations(filter: { scope: $scope, cityId: $cityId }, limit: $limit) {
        id
        name
        fullName
        locationGroupId
        slug
      }
    }
  `;
}

const temp = 'just for eslint';

export default temp;
