import styled, { css } from 'styled-components';
import { Typography } from '../ui/Typography';
import { FlexCenteredColumn } from '../../styled/common';

export const StyledFallbackView = styled(FlexCenteredColumn)`
  text-align: center;
  background-color: ${({ theme }) => theme.colors.background.primary};
  padding: 10vh 15px 15vh;

  @media (min-width: 480px) {
    margin-top: 50px;
  }
`;

export const IconWrapper = styled.div`
  margin-bottom: 15px;
`;

export const Title = styled(Typography)`
  display: block;
  width: 80%;
  margin: auto;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const Text = styled(Typography)`
  display: block;
  margin: 15px 0;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const buttonStyles = css`
  padding: 10px 15px;
  border-radius: 14px;
  cursor: pointer;
  width: max-content;
  height: 38px;
`;
