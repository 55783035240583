import { gql } from '@apollo/client';

export function bulkItems({ limit, id }) {
  return gql`
    query getBulkItems (
    $offset: Int!
  ) {
      items(filter: { companyId: ${id}}, limit: ${limit}, offset: $offset ) {
        id
        area {
          value
          units
        }
        leased
        floor
        floors
        city {
          id
          name
        }
        location {
          id
          name
          fullName
        }
        hasMortgage
        price {
          value
          currency
        }
        company {
          id
          name
          targetType
        }
        paidDaily
        rooms
        hasBillOfSale
        hasRepair
        vipped
        featured
        updatedAt
        path
        photosCount
        photos(limit: 4) {
          thumbnail
          f460x345
          large
        }
      }
    }
  `;
}

export const bulkPaidServices = gql`
  query paidServices($targetIds: [ID!]!) {
    paidServices(targetIds: $targetIds, targetType: ITEM) {
      id
      title
    }
  }
`;

export const bulkPaidServiceOptions = gql`
  query paidServiceOptions($paidServiceId: ID!, $targetIds: [ID!]!) {
    paidServiceOptions(paidServiceId: $paidServiceId, targetIds: $targetIds) {
      id
      name
      price
      priceWithDiscount(paymentMethodId: "wallet")
    }
  }
`;

export const bulkPaymentMethod = gql`
  query PaymentMethod($paidServiceId: ID!, $targetIds: [ID!]!) {
    paymentMethods(
      paidServiceId: $paidServiceId
      targetIds: $targetIds
      targetType: ITEM
    ) {
      id
      balance
      name
    }
  }
`;

const temp = 'just for eslint';

export default temp;
