import { gql } from '@apollo/client';

export const getDataForTransformationsQuery = gql`
  query GetDataForTransformationsQuery {
    cities(filter: { scope: ALL }) {
      id
      name
      slug
    }
    locations(filter: { scope: ALL }, limit: 500) {
      id
      name
      fullName
      locationGroupId
      slug
    }
    categories {
      id
      name
      pluralName
      slug
      children {
        id
        name
        pluralName
        slug
      }
    }
    rooms @client {
      id
      slug
      name
    }
    type @client {
      id
      name
    }
    sort @client {
      id
      params
      name
    }
  }
`;

export function getLocalDataQuery(id) {
  const name = `GetLocalDataQuery_${id}`;
  return gql`
    query ${name}($id: ID!) {
      city(id: $id) {
        id
        name
        slug
        locations {
          id
          name
          slug
        }
      }
      categories @client {
        id
        name
        pluralName
        slug
        children {
          id
          name
          pluralName
          slug
        }
      }
      rooms @client {
        id
        slug
      }
      sort @client {
        id
        params
        name
      }
    }
  `;
}
