import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/ru';
import 'dayjs/locale/az';
import { getLangDefinition } from './language';
import CONFIG from '../../config';
import {
  DEFAULT_CATEGORY_VALUES,
  TIME_FORMAT,
  PHONE_INTERNATIONAL_CODE
} from './constants';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatNumber = value => {
  if (typeof value === 'undefined') return value;
  const onlyNums = value.replace(/[^\d]/g, '').replace(/^0+/, '0');
  return onlyNums || undefined;
};

export const entitiesFilter = (array = [], searchString) => {
  return array.filter(
    item =>
      !item.name.toString().toLowerCase().indexOf(searchString.toLowerCase())
  );
};

export const isIOS = () => {
  if (typeof navigator !== 'undefined') {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }
  return false;
};

export const getGoogleApiKey = () => {
  const isRunAtLocalhost =
    typeof window !== 'undefined'
      ? window.location.origin.match(/(lvh\.me|localhost)/g)
      : false;
  return isRunAtLocalhost ? CONFIG.googleApiKeyLocalhost : CONFIG.googleApiKey;
};

export const getObjCopy = obj => (obj ? JSON.parse(JSON.stringify(obj)) : {});

export const formatNumberWithComma = number => {
  if (typeof number === 'undefined') return number;
  return number.toLocaleString('ru', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export const checkIsFlatCategory = categoryId => {
  const flatIds = ['1', '2', '3'];
  return flatIds.includes(categoryId);
};

export const isExistAndPositiveInteger = value => value && value > 0;

export const accusative = (word, lng) => {
  if (lng !== 'ru') {
    return word;
  }
  return word.replace(/а$/, 'у');
};

export const transformSlug = slug => {
  if (!slug) {
    return;
  }
  const slugArr = slug.split('/');
  slugArr.shift();
  return slugArr.join('/');
};

export const convertToNumber = value => {
  if ((typeof value === 'string' && !value.length) || value === undefined) {
    return;
  }
  return +value;
};

export const convertToBoolean = value => {
  if (typeof value !== 'boolean' || !value) {
    return;
  }
  return value;
};

export const getValueFromObject = (
  initialArray = [],
  property,
  returnProperty
) => {
  let result;
  let isExistInFirstLevel;

  if (!initialArray.length) {
    return;
  }

  for (let i = 0; i < initialArray.length; i += 1) {
    isExistInFirstLevel = Object.keys(initialArray[i])
      .map(k => initialArray[i][k])
      .includes(property);

    if (isExistInFirstLevel) {
      return initialArray[i][returnProperty];
    }

    if (initialArray[i].children) {
      result = initialArray[i].children.find(item =>
        Object.keys(item)
          .map(k => item[k])
          .includes(property)
      );

      if (result) {
        return result[returnProperty];
      }
    }
  }

  return result && result[returnProperty];
};

const checkTodayOrYesterday = (today, date, i18n) =>
  date.isBefore(today) ? i18n.t('common.yesterday') : i18n.t('common.today');

export const formatDate = (d, i18n, template = TIME_FORMAT.fullDateTime) => {
  const localeDate = dayjs(d).locale(getLangDefinition(i18n.language));

  return localeDate.format(template);
};

export const formatToBakuTime = (isoDateStr, i18n, template) => {
  const bakuTime = dayjs(isoDateStr).utc().tz('Asia/Baku');
  return formatDate(bakuTime, i18n, template);
};

export const dateToFormat = (date, format = 'DD MMMM YYYY, HH:mm') =>
  dayjs(date).format(format);

export const getCardTime = (i, i18n) => {
  const now = new Date();
  const todayMidnight = dayjs(now.setHours(0, 0, 0, 0));
  const localeDate = dayjs(i).locale(getLangDefinition(i18n.language));
  const { fullDateOnly, timeOnly } = TIME_FORMAT;

  if (dayjs(i).isBefore(todayMidnight.subtract(1, 'day'))) {
    return formatToBakuTime(i, i18n, fullDateOnly);
  }

  return `${checkTodayOrYesterday(
    todayMidnight,
    localeDate,
    i18n
  )} ${formatToBakuTime(i, i18n, timeOnly)}`;
};

export const toQueryString = (obj, prefix) => {
  const str = [];
  let newPrefix;
  let property;

  Object.keys(obj || {}).forEach(item => {
    newPrefix = prefix ? `${prefix}[]` : item.replace(/[[\]]/g, '');
    property = obj[item];

    str.push(
      typeof property === 'object'
        ? toQueryString(property, newPrefix)
        : `${encodeURI(newPrefix)}=${encodeURIComponent(property)}`
    );
  });

  return str.join('&');
};

export const isNotNil = value => value !== null && value !== undefined;

export const prettyNumber = number =>
  number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const getCallCenterPhones = () => {
  const {
    contacts: {
      tel,
      tel_url: telUrl,
      tel_additional: telAdditional,
      tel_additional_url: telAdditionalUrl
    }
  } = CONFIG;

  const phonesArr = [
    { tel, telUrl },
    { tel: telAdditional, telUrl: telAdditionalUrl }
  ];

  return phonesArr.filter(
    (phone, index) =>
      phonesArr.findIndex(item => item.tel === phone.tel) === index
  );
};

export const getFirstPaymentAmount = (price, percent) => {
  return Math.floor((price / 100) * percent);
};

export const setCoordinates = (data, change) => {
  const coordinates = {
    lat: data.latitude,
    lng: data.longitude
  };
  change(coordinates);
};

export const scrollInputToCenter = input => {
  const safeOffset = 60;
  if (isIOS()) {
    setTimeout(() => {
      if (input) {
        const rect = input.getBoundingClientRect();
        const inputBottom = rect.bottom + safeOffset;

        if (inputBottom > window.innerHeight) {
          input.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          });
        }
      }
    }, 100);
  }
};

export const isLeasedCategory = id => ['lease', 'sale'].includes(id);

export const snakeToCamelCase = str =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, group =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );
export const camelToSnakeCase = str =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const animateHorizontalScroll = (element, target, duration) => {
  const start = element.scrollLeft;
  const change = target - start;
  let startTime = null;

  const animate = timestamp => {
    if (!startTime) startTime = timestamp;
    const timeElapsed = timestamp - startTime;
    const progress = Math.min(timeElapsed / duration, 1);

    // eslint-disable-next-line no-param-reassign
    element.scrollLeft = start + change * progress;

    if (timeElapsed < duration) {
      requestAnimationFrame(animate);
    }
  };

  requestAnimationFrame(animate);
};

export const capitalizeFirstLetter = str =>
  str ? `${str.charAt(0).toUpperCase()}${str.slice(1)}` : '';

export const findCategory = ({ id, categories }) => {
  return categories.reduce((acc, cat) => {
    if (acc) return acc;
    if (cat.id === id) return cat;
    if (cat.children && cat.children.length) {
      const childResult = findCategory({ id, categories: cat.children });
      if (childResult) return childResult;
    }
    return acc;
  }, undefined);
};

export const getCategoryValues = ({ id, categories }) => {
  if (!id || !categories?.length) {
    return DEFAULT_CATEGORY_VALUES;
  }
  const {
    hasRooms,
    hasFloor,
    hasRepair,
    areaUnits,
    hasLandArea,
    photosRequired,
    hasBuildingType,
    areRoomsRequired
  } = findCategory({
    id,
    categories
  });

  return {
    hasRooms,
    hasFloor,
    hasRepair,
    areaUnits,
    hasLandArea,
    photosRequired,
    hasBuildingType,
    areRoomsRequired
  };
};

export const formatDateNumbers = (i, i18n) => {
  const localeDate = dayjs(i).locale(getLangDefinition(i18n.language));
  return localeDate.format('DD.MM.YYYY, HH:mm');
};

export const normalizePhoneNumber = phoneNumber => {
  const cleaned = `${phoneNumber}`.replace(/\D/g, '').split('');

  const joinSlice = (array, start, end) => {
    return array.slice(start, end).join('');
  };

  return `${joinSlice(cleaned, 0, 3)} ${joinSlice(cleaned, 3, 6)} ${joinSlice(
    cleaned,
    6,
    8
  )} ${joinSlice(cleaned, 8, 10)}`;
};

export const phoneWithInternationalCode = phone => {
  if (!phone) return;

  return phone.startsWith('(0')
    ? PHONE_INTERNATIONAL_CODE + phone.replace(/\D/g, '').replace(/^0/, '')
    : phone;
};

export const formatNumberWithTwoDecimals = number => {
  if (number) {
    return prettyNumber(number.toFixed(2).replace('.', ','));
  }
};

export const validateFunc = (t, validatorsConfig) => values =>
  Object.entries(validatorsConfig).reduce((errors, [fieldName, config]) => {
    const { required, validator, errorMessage } = config;
    const isRequired =
      typeof required === 'function' ? required(values) : required;
    const fieldValue = values[fieldName];
    const condition =
      !fieldValue || (validator && !validator(fieldValue, values));
    if (isRequired && condition) {
      const error =
        typeof errorMessage === 'function'
          ? errorMessage(values, t)
          : t(errorMessage);
      return { ...errors, [fieldName]: error };
    }
    return errors;
  }, {});

export const filterByKey = (
  items,
  targetObject,
  itemKeyPath,
  targetKeyPath
) => {
  if (!items || !targetObject || !itemKeyPath || !targetKeyPath) return null;

  const targetValue = targetKeyPath
    .split('.')
    .reduce((acc, key) => acc?.[key], targetObject);

  return (
    items.find(item => {
      const itemValue = itemKeyPath
        .split('.')
        .reduce((acc, key) => acc?.[key], item);
      return itemValue === targetValue;
    }) || null
  );
};
