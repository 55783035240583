import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import CONFIG from '../../config';
import { makeUrlWithLang } from '../../utils/helpers';

const { asset_host: assetHost, languages } = CONFIG;

interface HostSum {
  currentUrl: string;
  protocol: string;
  host: string;
  path: string;
}

export const HeadMeta = (hostSum: HostSum) => {
  const router = useRouter();

  const [currHostSum, setCurrHostSum] = React.useState(hostSum);

  const defaultURL = `${CONFIG.protocol}://${currHostSum.host}`;
  const ruURL = `${CONFIG.protocol}://ru.${currHostSum.host}`;

  React.useEffect(() => {
    if ((!hostSum || !hostSum?.path) && typeof window !== 'undefined') {
      const { host: locationHost, protocol } = window.location;
      setCurrHostSum({
        currentUrl: `${protocol}://${locationHost}${router.asPath}`,
        protocol,
        host: locationHost,
        path: router.asPath
      });
    }
  }, [router.asPath]);

  return (
    <Head>
      <meta
        name="google-site-verification"
        content="lbsPKTikII78xEIjMLTs-ZrNnHmog26F7esxmv_YNuc"
      />
      <meta
        key="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover, interactive-widget=resizes-content"
        name="viewport"
      />
      <meta name="google" content="notranslate" />
      <meta
        key="format-detection"
        content="telephone=no"
        name="format-detection"
      />
      <meta key="theme-color" content="#ad7a5b" name="theme-color" />

      <link
        href="/static/favicons/favicon-192x192.png"
        rel="apple-touch-icon"
        sizes="192x192"
      />
      <link
        href="/static/favicons/favicon-192x192.png"
        rel="icon"
        sizes="192x192"
        type="image/png"
      />
      <link
        color="#ad7a5b"
        href="/static/favicons/favicon.svg"
        rel="mask-icon"
      />
      <meta
        key="msapplication-config"
        content="/static/browserconfig.xml"
        name="msapplication-config"
      />
      <link href="/static/manifest.webmanifest" rel="manifest" />
      {assetHost && <link href={`//${assetHost}`} rel="dns-prefetch" />}
      <link href={currHostSum.currentUrl} rel="canonical" />
      <link rel="alternate" hrefLang="az" href={defaultURL} />
      <link rel="alternate" hrefLang="ru" href={ruURL} />
      <link rel="alternate" hrefLang="x-default" href={defaultURL} />
      {languages.map((lang, key) => (
        <link
          key={key}
          hrefLang={lang}
          rel="alternate"
          href={makeUrlWithLang({ ...hostSum, lang })}
        />
      ))}
    </Head>
  );
};
