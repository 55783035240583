import { createStore } from 'redux';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension';
import combinedReducer from '../reducers';

const bindMiddleware = () => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools();
  }
  return undefined;
};

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    return {
      ...state,
      ...action.payload
    };
  }
  return combinedReducer(state, action);
};

const makeStore = () => createStore(reducer, bindMiddleware());

export default createWrapper(makeStore);
