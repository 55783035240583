import { gql } from '@apollo/client';

export const staticFooterDataQuery = gql`
  query StaticFooterData {
    regions(cityId: 1) {
      id
      name
      latitude
      longitude
      slug
    }
    cities(filter: { scope: UNPOPULAR }, limit: 80) {
      id
      name
      slug
    }
  }
`;
