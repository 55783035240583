import { DefaultStyles } from '../types';
import { FontWeight } from '../constants';

export interface ButtonStyles {
  color: string;
  background: string;
  border?: string;
  fontWeight?: FontWeight;
}

export enum ButtonType {
  default = 'default',
  edit = 'edit',
  white = 'white',
  delete = 'delete',
  green = 'green',
  kapitalBank = 'kapitalBank',
  link = 'link',
  signIn = 'signIn',
  grey = 'grey'
}

export type ButtonColorsMap = {
  [key in ButtonType]: ButtonStyles;
};

export interface GlobalSystemColors {
  white: string;
  heatheredGrey: string;
  wildSandGrey: string;
  blue: string;
  green: string;
  red: string;
  auburnRed: string;
  kapital: string;
  yellow: string;
  black: string;
  cocoaBrown: string;
}

interface ExtendedSystemColors extends GlobalSystemColors {
  brand: string;
  lightBlue: string;
  lightRed: string;
}

export interface Colors {
  text: {
    primary: string;
    secondary: string;
    basic: string;
    platinum: string;
    silver: string;
    gold: string;
    white: string;
  };
  background: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  border: {
    primary: string;
    secondary: string;
  };
  systemColors: ExtendedSystemColors;
  promotion: {
    bump: string;
    vip: string;
    premium: string;
  };
}

export interface ThemeStyles extends DefaultStyles {
  colors: Colors;
}

export interface Theme extends ThemeStyles {
  isWebView?: boolean;
  webview?: { insets: unknown };
  button: ButtonColorsMap;
}
