import React, { ReactNode } from 'react';
import Image from 'next/image';
import WhiteHeader from '../WhiteHeader';
import {
  buttonStyles,
  IconWrapper,
  StyledFallbackView,
  Text,
  Title
} from './styled';
import { Button } from '../ui/Button';

interface FallbackViewProps {
  title?: string;
  Icon?: ReactNode;
  icon?: string;
  text: string | ReactNode;
  isShowHeader?: boolean;
  onClickHandler?: () => void;
  btnText?: string;
  width: number;
  height: number;
}

export const FallbackView: React.FC<FallbackViewProps> = ({
  Icon,
  icon,
  text,
  title,
  btnText,
  isShowHeader = false,
  onClickHandler,
  ...rest
}) => {
  return (
    <>
      {isShowHeader && <WhiteHeader />}
      <StyledFallbackView data-cy="fallback-view">
        <IconWrapper data-cy="fallback-view__icon">
          {icon ? (
            <Image alt="" src={icon} {...rest} />
          ) : (
            <Icon alt="" {...rest} />
          )}
        </IconWrapper>
        {title && (
          <Title tag="body_1" data-cy="fallback-view__title">
            {title}
          </Title>
        )}
        <Text tag="body_2" data-cy="fallback-view__text">
          {text}
        </Text>
        {btnText && onClickHandler && (
          <Button
            onClick={onClickHandler}
            cypress="fallback-view__btn"
            text={btnText}
            styles={buttonStyles}
          />
        )}
      </StyledFallbackView>
    </>
  );
};
