import { createAction } from 'redux-actions';

export const toggleDrawer = createAction('DRAWER_TOGGLE');
export const toggleAuthModal = createAction('AUTH_MODAL_TOGGLE');
export const setEmail = createAction('AUTH_MODAL_SET_EMAIL');

export const setNotification = createAction('AUTH_MODAL_SET_NOTIFICATION');
export const closeNotification = createAction('AUTH_MODAL_CLOSE_NOTIFICATION');
export const clearNotificationMessage = createAction(
  'AUTH_MODAL_CLEAR_NOTIFICATION_MESSAGE'
);

export const toggleSideMenuModal = createAction('SIDE_MENU_MODAL_TOGGLE');
export const togglePromotionModalView = createAction(
  'PROMOTION_MODAL_VIEW_TOGGLE'
);
export const setProfilePromotionItemId = createAction(
  'SET_PROFILE_PROMOTION_ITEM_ID'
);
export const toggleRestoreAlertModal = createAction(
  'RESTORE_ALERT_MODAL_TOGGLE'
);
export const openSuccessPromotionAlertModal = createAction(
  'OPEN_SUCCESS_PROMOTION_ALERT_MODAL'
);
export const toggleAddNewItemModal = createAction('ADD_NEW_ITEM_MODAL_TOGGLE');
export const setCreationResetButtonIsDisabled = createAction(
  'SET_CREATION_RESET_BUTTON_IS_DISABLED'
);
export const openFailurePaymentAlertModal = createAction(
  'OPEN_FAILURE_PAYMENT_ALERT_MODAL'
);
export const openItemsBalanceModal = createAction('OPEN_ITEMS_BALANCE_MODAL');
export const openBuyItemsModal = createAction('OPEN_BUY_ITEMS_MODAL');
export const openWalletPayModal = createAction('OPEN_WALLET_PAY_MODAL');
export const openPersonalAccountModal = createAction(
  'OPEN_PERSONAL_ACCOUNT_MODAL'
);
export const toggleProfilePromotionItemsModal = createAction(
  'TOGGLE_PROFILE_PROMOTION_ITEMS_MODAL'
);
export const openTerminalModal = createAction('OPEN_TERMINAL_MODAL');
export const openPackagePayModal = createAction('OPEN_PACKAGE_PAY_MODAL');

export const openSuccessWebViewPaymentStatusAlertModal = createAction(
  'OPEN_SUCCESS_WEBVIEW_PAYMENT_STATUS_ALERT_MODAL'
);
export const openFailureWebViewPaymentStatusAlertModal = createAction(
  'OPEN_FAILURE_WEBVIEW_PAYMENT_STATUS_ALERT_MODAL'
);

export const setBlockerDetector = createAction('SET_BLOCKER_DETECTOR');
export const switchTab = createAction('SEARCH_FORM_SWITCH_TAB');
export const setSearchSort = createAction('SET_SEARCH_SORT');
export const showPopupHeader = createAction('SHOW_POPUP_HEADER');
export const hidePopupHeader = createAction('HIDE_POPUP_HEADER');
export const setCurrentCity = createAction('SET_CURRENT_CITY');
export const setSearchValues = createAction('SET_SEARCH_VALUES');
export const setResetPopupHandler = createAction('SET_RESET_POPUP_HANDLER');
export const setLocationsResetButtonIsDisabled = createAction(
  'SET_LOCATIONS_RESET_BUTTON_IS_DISABLED'
);
export const setResetButtonIsDisabled = createAction(
  'SET_RESET_BUTTON_IS_DISABLED'
);
export const toggleAuthWindow = createAction('TOGGLE_AUTH_WINDOW');
export const setChosenLocationNames = createAction('SET_CHOSEN_LOCATION_NAMES');
export const setLocationIds = createAction('SET_LOCATION_IDS');
export const setCitiesList = createAction('SET_CITIES_LIST');
export const setCurrentState = createAction('SET_CURRENT_STATE');
export const setError = createAction('SET_ERROR');
export const setCalcSelectionsState = createAction('SET_CALC_SELECTIONS_STATE');
export const setCalcTermState = createAction('SET_CALC_TERM_STATE');
export const setCreditAmountState = createAction('SET_CREDIT_AMOUNT_STATE');
export const setCalcFirstPaymentState = createAction(
  'SET_CALC_FIRST_PAYMENT_STATE'
);
export const setEmptyCalcState = createAction('SET_EMPTY_CALC_STATE');
export const setProfileAuth = createAction('SET_PROFILE_AUTH');
export const setIsLoadingResultByNumber = createAction(
  'SET_IS_LOADING_RESULT_BY_NUMBER'
);
export const setResultByNumber = createAction('SET_RESULT_BY_NUMBER');
export const setCategoryIds = createAction('SET_CATEGORY_IDS');
export const showProfileEditSuccessMessage = createAction(
  'SHOW_PROFILE_EDIT_SUCCESS_MESSAGE'
);
export const setAdriverSelectedCity = createAction('SET_ADRIVER_SELECTED_CITY');

export const setUserCardsState = createAction('SET_USER_CARDS');
