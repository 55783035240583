import { GlobalSystemColors } from './types';

export enum ThemeType {
  light,
  dark
}

export const globalSystemColors: GlobalSystemColors = {
  white: '#fff',
  heatheredGrey: '#98918a',
  wildSandGrey: '#eae5e1',
  blue: '#4264fd',
  green: '#3db460',
  red: '#ff586d',
  auburnRed: '#d93d04',
  kapital: '#ec3342',
  yellow: '#ffb900',
  black: '#121212',
  cocoaBrown: '#2f1f19'
};
