import React from 'react';
import App from 'next/app';
import Router from 'next/router';
import { I18nextProvider } from 'react-i18next';
import { ApolloProvider } from '@apollo/client';
import { DefaultSeo } from 'next-seo';
import NProgress from 'nprogress';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import Script from 'next/script';
import { ThemeWrapper } from '../styled/theme/wrapper';
import reduxWrapper from '../store';
import i18n from '../utils/i18n';
import { createSeoConfig } from '../utils/helpers';
import ErrorBoundary from '../components/ErrorBoundary';
import withApolloClient from '../utils/with-apollo-client';
import countersLoader from '../utils/counters';
import CONFIG from '../config';
import { HeadMeta } from '../components/HeadMeta';
import ErrorPage from '../components/ErrorPage';
import adriverCallback from '../components/Banner/genericCallback';
import BridgeProvider from '../bridge';
import '../styles/index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { GlobalStyles } from '../styled/global.styled';
import { noTopInsert } from '../bridge/utils/noTopInsert';

const { gtmContainerId, fbAppId } = CONFIG;
const { tagManager, li } = countersLoader;

function shouldForwardProp(propName, target) {
  if (typeof target === 'string') {
    return isPropValid(propName);
  }
  return true;
}

const onlyProductionContent = renderContent => {
  if (window.location.hostname?.indexOf('bina.az') > -1) {
    renderContent();
  }
};

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};
    const { store, pathname } = ctx;

    store.dispatch({ type: 'DRAWER_TOGGLE', payload: false });

    try {
      if (ctx && ctx.res) {
        ctx.res.setHeader(
          'Cache-Control',
          's-maxage=1, stale-while-revalidate=59, max-age=0, private, must-revalidate'
        );
      }
      pageProps = Component.getInitialProps
        ? await Component.getInitialProps(ctx, i18n)
        : {};
    } catch (error) {
      console.log(`Error occurred: ${error}`);
      pageProps.statusCode = 500;
      pageProps.seoData = {};
      pageProps.pathname = pathname;
    }

    const userAgent =
      typeof window === 'undefined'
        ? ctx.req?.headers['user-agent']
        : window.navigator.userAgent;

    pageProps.isSSRWebView = userAgent?.indexOf('miniApp') >= 0;

    return {
      pageProps
    };
  }

  componentDidMount() {
    const { apolloClient, pageProps } = this.props;
    NProgress.configure({ showSpinner: false, trickleSpeed: 50 });

    if (pageProps.isSSRWebView) {
      noTopInsert(); // if this is the first page
      Router.router.events.on('beforeHistoryChange', noTopInsert);
    }

    Router.router.events.on('routeChangeStart', url => {
      if (!url.match(/\/items\/(\d+)/)) {
        NProgress.start();
      }
    });
    onlyProductionContent(() => {
      li();
      tagManager(gtmContainerId);
    });
    Router.router.events.on('routeChangeComplete', () => {
      onlyProductionContent(() => li());
      NProgress.done();
      if (Router.router.state.route === '/') {
        apolloClient.reFetchObservableQueries();
      }
    });
    document.body.classList.remove('preload');
  }

  render() {
    const { Component, pageProps, apolloClient, router, lng } = this.props;
    const { seoData, statusCode, isSSRWebView } = pageProps;
    const path = router.state?.asPath || '/';

    const defaultHostSum = {
      currentUrl: `${CONFIG.protocol}://${lng}.${CONFIG.host}`,
      protocol: CONFIG.protocol,
      host: `${lng}.${CONFIG.host}`,
      path
    };

    const seo = seoData?.hostSum?.path
      ? seoData
      : { ...seoData, hostSum: defaultHostSum };
    const seoConfig = createSeoConfig(seo, fbAppId, i18n);
    const getLayout = Component.getLayout || (page => page);

    if (typeof window !== 'undefined') {
      window.adriverExtentionLoad = adriverCallback;
    }

    if (statusCode === 404) {
      return (
        <I18nextProvider i18n={i18n}>
          <ErrorPage statusCode={statusCode} />
        </I18nextProvider>
      );
    }

    return (
      <BridgeProvider isWebView={isSSRWebView}>
        <ThemeWrapper>
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <GlobalStyles />
            <I18nextProvider i18n={i18n}>
              {seoConfig ? (
                <>
                  <HeadMeta {...seo.hostSum} />
                  <Script src="/adriver.core.2.min.js" />
                  <DefaultSeo {...seoConfig} />
                </>
              ) : null}
              <ApolloProvider client={apolloClient}>
                {getLayout(
                  <ErrorBoundary
                    statusCode={statusCode}
                    i18n={i18n}
                    router={Router}
                    pageProps={pageProps}
                  >
                    <Component {...pageProps} />
                  </ErrorBoundary>,
                  pageProps
                )}
              </ApolloProvider>
            </I18nextProvider>
          </StyleSheetManager>
        </ThemeWrapper>
      </BridgeProvider>
    );
  }
}

export default withApolloClient(reduxWrapper.withRedux(MyApp));
