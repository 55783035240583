export const STATIC_PAGES = {
  ABOUT: '/pages/about',
  PACKAGES: '/pages/packages',
  PRIVACY: '/pages/privacy',
  PROPOSAL: '/pages/proposal',
  RULES: '/pages/rules',
  TERMS: '/pages/terms-and-conditions'
};

export const STATIC_SLUG = {
  about: 'ABOUT',
  advertise: 'AD',
  packages: 'PACKAGES',
  privacy: 'PRIVACY',
  proposal: 'PROPOSAL',
  rules: 'RULES',
  'terms-and-conditions': 'TERMS'
};

export const MAX_PHOTOS_LENGTH = 30;
export const DEFAULT_PHOTOS_LENGTH = 4;

export const BUILDING_TYPE_ID = {
  FLAT_ALL: '1',
  FLAT_NEW: '2',
  FLAT_SECONDARY: '3',
  HOUSE: '5',
  OFFICE: '7',
  GARAGE: '8',
  AREA: '9',
  OBJECT: '10'
};

export const MODAL_ANIMATION_TIME = 300;

export const urlHashes = Object.freeze({
  authentication: '#authentication',
  more: '#more',
  serviceBump: '#service_bump',
  serviceFeature: '#service_feature',
  serviceVip: '#service_vip',
  restore: '#restore',
  personalAds: '#personal_ads',
  limitPayment: '#limit_payment',
  photosGrid: 'photos_grid',
  fullScreenGallery: 'full_screen_gallery',
  authorizationByPhone: 'authorization_by_phone'
});

export const shortPromotionHashes = [
  urlHashes.serviceBump,
  urlHashes.serviceFeature,
  urlHashes.serviceVip
];

export const longPromotionHashes = [
  urlHashes.serviceBump,
  urlHashes.serviceFeature,
  urlHashes.serviceVip,
  urlHashes.restore,
  urlHashes.personalAds,
  urlHashes.limitPayment
];

export const MAX_INT_VALUE = 2_147_483_647;

export const FORM_VALUES = {
  leased: 'isLeased',
  cityId: 'city_id',
  villageId: 'townshipId',
  villageName: 'villageName',
  categoryId: 'categoryId',
  locationId: 'regionId',
  locationIds: 'landmarkIds',
  locationName: 'locationName',
  coordinates: 'coordinates',
  address: 'address',
  roomsAmount: 'rooms',
  area: 'area',
  landArea: 'landArea',
  floor: 'floor',
  totalFloors: 'totalFloors',
  hasRepair: 'hasRepair',
  buildingType: 'buildingType',
  paidDaily: 'paidDaily',
  price: 'price',
  hasBillOfSale: 'hasBillOfSale',
  hasMortgage: 'hasMortgage',
  description: 'description',
  owner: 'owner',
  name: 'name',
  email: 'email',
  phone: 'phone',
  company: 'company',
  code: 'code',
  typeEstate: 'typeEstate',
  images: 'photos',
  categoryValues: 'categoryValues',
  cityHasLocations: 'cityHasLocations'
};

export const DEFAULT_CATEGORY_VALUES = {
  areaUnits: 'm²',
  hasRooms: false,
  hasFloor: false,
  hasRepair: false,
  hasLandArea: false,
  hasBuildingType: false,
  areRoomsRequired: true,
  photosRequired: DEFAULT_PHOTOS_LENGTH
};

export const INITIAL_FORM_VALUES = {
  [FORM_VALUES.cityId]: '1',
  [FORM_VALUES.owner]: 'OWNER',
  [FORM_VALUES.leased]: 'false',
  [FORM_VALUES.hasBillOfSale]: false,
  [FORM_VALUES.hasMortgage]: false,
  [FORM_VALUES.categoryValues]: DEFAULT_CATEGORY_VALUES,
  [FORM_VALUES.cityHasLocations]: true
};

export const PROFILE_FORM_VALUES = {
  name: 'name',
  email: 'email',
  phone: 'phone'
};

export const PROFILE_PASSWORD_FORM_VALUES = {
  currentPassword: 'currentPassword',
  newPassword: 'newPassword',
  confirmPassword: 'confirmPassword'
};

export const initialWalletPayValue = '20';
export const walletPayMaxValue = 5000;
export const walletPayPortmanatMaxValue = 1000;

export const TIME_FORMAT = {
  fullDateTime: 'DD MMMM YYYY, HH:mm',
  timeOnly: 'HH:mm',
  fullDateOnly: 'DD MMMM YYYY',
  numDateTime: 'DD.MM.YYYY, HH:mm'
};

export const Cities = {
  Baku: { id: '1', az: 'Bakı' },
  Sumgait: { id: '3', az: 'Sumqayit' },
  Khyrdalan: { id: '34', az: 'Xirdalan' }
};

export const INITIAL_SEARCH_FORM_VALUES = {
  leased: 'false',
  categoryId: BUILDING_TYPE_ID.FLAT_NEW,
  cityId: Cities.Baku.id
};

export const REQUEST_STATUSES = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  INVALID: 'INVALID',
  REDIRECT: 'REDIRECT',
  UNHANDLED: 'UNHANDLED'
};

export const CREATION_INVOICE_STATUS = {
  TODAY: 'TODAY',
  POSTPONE: 'POSTPONE'
};

export const INVOICE_STATUS = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  OVERDUE: 'OVERDUE',
  CANCELED: 'CANCELED',
  REVOKED: 'REVOKED'
};

export const PROFILE_BUSINESS_TABS = {
  packages: 'packages',
  business: 'business',
  invoices: 'invoices'
};

export const PROMOTION_SERVICES_ARRAY = ['bump', 'vip', 'feature'];
export const PROMOTION_SERVICES_ARRAY_WITH_PRICE = [
  {
    name: 'bump',
    price: 3
  },
  {
    name: 'vip',
    price: 4
  },
  {
    name: 'feature',
    price: 6
  }
];

export const PROFILE_ROUTES = {
  PROFILE: '/profile',
  SETTINGS: '/profile/settings',
  INFO: '/profile/info',
  EDIT: '/profile/edit',
  PACKAGES: '/profile/packages',
  PACKAGES_PAYMENT: '/profile/packages/payment',
  PAYMENTS: '/profile/payments',
  TRANSACTIONS: '/profile/transactions',
  PAID_ADS_TRANSACTIONS: '/profile/paid_ads_transactions',
  INVOICES: '/profile/invoices',
  USER_CARDS: '/profile/user_cards'
};

export const PHONE_INTERNATIONAL_CODE = '+994';

export const AUTH_ROUTES = {
  SESSION: '/company_session/new',
  REQUEST_TO_RESET_PASSWORD: '/reset_password/new',
  RESET_PASSWORD: '/reset_password'
};

export const RAILS_ROUTES = {
  MAP: 'https://bina.az/baki/alqi-satqi/menziller?items_view=map'
};

export const CATEGORY_ROOMS = ['1', '2', '3', '4', '5+'];

export const FLAT_CATEGORIES = [
  BUILDING_TYPE_ID.FLAT_ALL,
  BUILDING_TYPE_ID.FLAT_NEW,
  BUILDING_TYPE_ID.FLAT_SECONDARY,
  BUILDING_TYPE_ID.HOUSE,
  BUILDING_TYPE_ID.OFFICE
];

export const LEASED_TYPES = [
  { value: 'false', name: 'new_search.purchase', id: 'new-search-form-buy' },
  { value: 'true', name: 'new_search.lease', id: 'new-search-form-rent' }
];

export const DISTRICT_ID = '2';
export const TOWNSHIP_ID = '3';

export const SEARCH_RESULT_ROUTES = {
  SALE: '/alqi-satqi',
  RENT: '/kiraye',
  RENT_DAILY: '/kiraye?paid_daily=true',
  ITEMS_ALL: '/items/all'
};

export const SORTING_VARIANTS = [
  { id: '1', name: 'new_search.sort.by_date', stat: 'sort-by-date' },
  { id: '2', name: 'new_search.sort.by_area_asc', stat: 'sort-by-area-asc' },
  { id: '3', name: 'new_search.sort.by_price_asc', stat: 'sort-by-price-asc' },
  { id: '4', name: 'new_search.sort.by_price_desc', stat: 'sort-by-price-desc' }
];

export const LOCATION_MODAL_TABS_STATS = {
  2: 'location-tab-district',
  1: 'location-tab-metro',
  4: 'location-tab-landmark'
};

export const ANY_CITY_ID = '0';

export const ADRIVER_CATEGORIES_NAME = {
  1: 'Bagh',
  2: 'Yeni-tikili',
  3: 'Kohne-tikili',
  5: 'Ev-villa',
  7: 'Ofis',
  8: 'Qaraj',
  9: 'Torpaq',
  10: 'Obyekt'
};

export const TERMS_WITH_CUSTOM_LABELS = [
  'four_ads',
  'fifteen_ads',
  'four_paid_ads',
  'fifteen_paid_ads'
];

export const TERMS_WITH_LABELS = ['vip1', 'featured1'];
