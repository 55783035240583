import { createGlobalStyle } from 'styled-components';
import { Theme } from './theme/types';
import { defaultStyles } from './constants';

export const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
  html,
  body,
  .app,
  #__next {
    -webkit-overflow-scrolling: touch;
  }

  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
    font: normal ${defaultStyles.baseFontSize} ${defaultStyles.fontFamily};
    color: ${({ theme: { colors } }) => colors.text.primary};
    -webkit-font-smoothing: antialiased;
  }

  section,
  article,
  nav,
  header,
  footer,
  aside,
  figure {
    display: block;
  }

  a {
    color: #333;
    text-decoration: underline;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    img {
      border: 0;
    }
  }

  h3 {
    margin-top: 0;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 7px;
    font-weight: bold;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }

  button {
    border: 0;
    outline: none;
    appearance: none;
  }

  .area {
    white-space: nowrap;
  }

  .main-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: ${({ theme: { colors } }) => colors.background.primary};

    @media screen and (orientation: landscape) {
      min-height: 320px;
    }
  }

  .preload {
    width: calc(100% - 0px);
    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;

    * {
      transition: none !important;
    }
  }

  .is-hidden {
    display: none !important;
  }
`;
