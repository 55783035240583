import {
  BUILDING_TYPE_ID,
  Cities,
  INITIAL_SEARCH_FORM_VALUES
} from '../utils/helpers/constants';

const initialSortingValue = '1';

const initialSearchFormValues = {
  city_id: Cities.Baku.id,
  leased: 'false',
  category_id: BUILDING_TYPE_ID.FLAT_NEW,
  room_ids: undefined,
  price_from: undefined,
  price_to: undefined,
  area_from: undefined,
  area_to: undefined,
  land_area_from: undefined,
  land_area_to: undefined,
  paid_daily: undefined,
  has_bill_of_sale: undefined,
  only_residences: undefined,
  has_mortgage: undefined,
  has_repair: undefined,
  floor_from: undefined,
  floor_to: undefined,
  floor_first: undefined,
  floor_last: undefined,
  location_ids: [],
  building_type: undefined,
  sorting: initialSortingValue
};

export default {
  calcForm: {
    has_bill_of_sale: true,
    official_income: null,
    calc_user_name: '',
    calc_phone_number: '',
    term: 10,
    creditRate: 18,
    creditAmount: 0,
    firstPayment: 10000,
    firstPaymentRate: 15
  },
  searchForm: {
    'popup-header': true,
    currentTabId: 0,
    currentSortType: initialSortingValue,
    price_from: '',
    locationIds: [],
    error: false,
    resetPopupHandler: null,
    resetButtonIsDisabled: false,
    locationsResetButtonIsDisabled: false,
    chosenLocationNames: [],
    currentCity: '',
    defaultState: INITIAL_SEARCH_FORM_VALUES,
    currentState: initialSearchFormValues,
    isLoadingResultByNumber: false,
    resultByNumber: { hasAnyResult: false, result: null },
    isClickedEmptyResultByNumber: false,
    categoryIds: []
  },
  drawerUI: {
    isVisible: false
  },
  errorUI: {
    isError: false
  },
  blockerDetector: {
    isBlockerActive: false
  },
  authModal: {
    isVisible: false,
    email: ''
  },
  sideMenuModal: {
    isVisible: false
  },
  restoreAlertModal: {
    isVisible: false
  },
  promotionModalView: {
    isVisible: false
  },
  successPromotionModalView: {
    isVisible: false,
    name: ''
  },
  failurePaymentModalView: {
    isVisible: false,
    name: ''
  },
  addNewItemModal: {
    isVisible: false,
    cityHasLocations: true,
    isOpenAuthWindow: false,
    creationResetButtonIsDisabled: false
  },
  itemsBalanceModalView: {
    isVisible: false
  },
  buyItemsModalView: {
    isVisible: false
  },
  walletPayModalView: {
    isVisible: false
  },
  terminalModalView: {
    isVisible: false
  },
  personalAccountModalView: {
    isVisible: false
  },
  profilePromotionItemId: {
    profileItemId: null
  },
  profilePromotionItemsModalView: {
    isVisible: false
  },
  packagePayModalView: {
    isVisible: false
  },
  profileEditSuccessMessage: {
    isVisible: false
  },
  successWebviewPaymentStatusModalView: {
    isVisible: false,
    payment: null
  },
  failureWebviewPaymentStatusModalView: {
    isVisible: false,
    type: 'error'
  },
  userCards: {
    cardsList: []
  },
  notification: {
    message: null,
    isClosing: false,
    targetPage: null
  },
  adriverBanner: {
    selectedCityName: Cities.Baku.az
  }
};
