import { gql } from '@apollo/client';

export const citiesListQuery = gql`
  query CitiesListQuery {
    cities(filter: { scope: ALL }) {
      id
      name
      slug
      latitude
      longitude
    }
  }
`;

export const citiesPopularListQuery = gql`
  query CitiesPopularListQuery {
    cities(filter: { scope: POPULAR }, limit: 10) {
      id
      name
      slug
    }
  }
`;
