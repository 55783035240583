export const getLangDefinition = lang => {
  switch (true) {
    case lang === 'ru':
      return lang;
    default:
      return 'az';
  }
};

export const getReversedLangDefinition = lang => {
  switch (lang) {
    case 'ru':
      return 'az';
    default:
      return 'ru';
  }
};
